@import "partial/typography";
@import "partial/iconBox";
@import "partial/general";
@import "partial/footer";


:root {
  --dimensions-intro-mouse: 5rem;

  @media only screen and (max-width: 1200px) {
    --dimensions-intro-mouse: 12vw;
  }
}

header {
  position: relative;

  @media only screen and (max-width: 576px) {
    padding-top: 30vw;
  }

  .bg-header {
    @extend .parallax-bg;

    background-image: url(../images/../new-images/living_room_default.jpg);
    height: 100vh;

    max-height: 150vw;

    @media (max-width: 768px) {
      max-height: 75vh;
    }

    @media only screen and (max-width: 576px) {
      max-height: 80vw;
    }

    @media (min-width: 432px) {
      background-image: url(../images/../new-images/living_room_432.jpg);
    }

    @media (min-width: 768px) {
      background-image: url(../images/../new-images/living_room_768.jpg);
    }

    @media (min-width: 1024px) {
      background-image: url(../images/../new-images/living_room_1024.jpg);
    }

    @media (min-width: 1440px) {
      background-image: url(../images/../new-images/living_room_1440.jpg);
    }
  }
}

.slider {
  // The problem for iOS solved below ⬇️
  position: relative;
  //filter: drop-shadow(-23.941px -65.778px 100px rgba(0, 0, 0, 0.4));

  box-shadow: -23.941px -65.778px 100px rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(90deg, #000 0%, transparent 50%, #000 100%);
  background-color: rgba(0, 0, 0, 0.6);
  background-size: 1920px 100%;
  background-position: 50% 0;
  margin-bottom: -463px;

  padding: 90px 15px 83px 15px;

  @media only screen and (max-width: 1440px) {
    padding: 60px 15px 50px 15px;
  }

  @media only screen and (max-width: 1200px) {
    margin-bottom: -120px;
    padding: 30px 15px;

    background-image: linear-gradient(
        90deg,
        #000 0%,
        transparent 50%,
        #000 100%
      ),
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0, 0, 0, 0.8) 50%,
        transparent 100%
      );
  }

  @media only screen and (max-width: 576px) {
    margin-bottom: -48px;

    background-color: #000;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: max(-1.5vw, -11px);
    left: 0;
    width: 45vw;
    height: 3px;

    background-image: linear-gradient(
      90deg,
      #000 0%,
      transparent 50%,
      #000 100%
    );
    background-color: rgba(0, 0, 0, 0.6);
    background-size: 1920px 100%;
    background-position: 50% 0;

    @media only screen and (max-width: 432px) {
      content: none;
    }
  }

  .link {
    text-align: center;
  }

  img {
    max-width: 14%;
    @media only screen and (max-width: 576px) {
      max-width: 30%;
    }
  }

  p {
    margin-top: 66px;
    font-weight: 200;
    text-align: center;
    color: #fff;
    line-height: 36px;
    font-size: 23px;
    margin-bottom: 0;

    @media only screen and (max-width: 1440px) {
      margin-top: 48px;
    }

    @media only screen and (max-width: 1200px) {
      margin-bottom: 0;
      font-size: 20px;
      line-height: normal;
      margin-top: 15px;
    }

    @media only screen and (max-width: 576px) {
      margin-top: 15px;
      font-size: 16px;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

.top-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-image: linear-gradient(
    180deg,
    #000 0%,
    #000 20%,
    rgba(0, 0, 0, 0) 100%
  );
  background-color: rgba(0, 0, 0, 0.7);
  background-size: 1920px 100%;
  filter: drop-shadow(12px 69px 75px rgba(0, 0, 0, 0.6));

  z-index: 10;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  @media only screen and (max-width: 576px) {
    background-image: linear-gradient(
        180deg,
        #000 0%,
        #000 20%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(to bottom, #000 0, #000 80%, transparent 100%);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -12px;
    width: 35vw;
    height: 4px;

    background-color: rgba(0, 0, 0, 0.7);
    background-size: 1920px 100%;

    @media only screen and (max-width: 768px) {
      bottom: max(-2vw, -12px);

      background-color: rgba(0, 0, 0, 0.8);
    }

    @media only screen and (max-width: 432px) {
      content: none;
    }
  }

  .section-container {
    padding: 32px 16px;
    justify-content: space-between;
    align-items: center;
    width: auto;

    @media only screen and (max-width: 1440px) {
      flex-wrap: wrap;
      padding: 15px;
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    max-width: 320px;
  }

  .logo-okna {
    order: 0;
    flex: 0 1 20%;

    @media only screen and (max-width: 1440px) {
      flex-basis: 223px;
    }

    @media only screen and (max-width: 576px) {
      flex: 0 1 50%;
      order: 0;
    }
  }

  .amadeus-logo {
    order: 2;
    flex: 0 1 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      width: 100%;
      max-width: 105px;
    }

    img {
      max-width: 105px;
    }

    @media only screen and (max-width: 1440px) {
      order: 1;

      flex-basis: 106px;
    }

    @media only screen and (max-width: 576px) {
      flex: 0 1 50%;
      order: 1;
      margin-left: auto;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    order: 1;
    flex: 1 1 100%;

    @media only screen and (max-width: 1440px) {
      flex: 1 1 100%;
      order: 2;
      margin-top: 24px;
    }

    @media only screen and (max-width: 576px) {
      flex-wrap: wrap;
    }

    h1 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 52px;
      font-weight: 200;

      @media only screen and (max-width: 820px) {
        font-size: 37px;
      }

      @media only screen and (max-width: 576px) {
        font-size: 29px;
        width: 100%;
      }
    }

    span {
      text-transform: uppercase;
      font-weight: bold;

      @media only screen and (max-width: 820px) {
        font-size: 19px;
      }
    }
  }
}

.intro__mouse {
  height: var(--dimensions-intro-mouse, 5vw);
  width: var(--dimensions-intro-mouse, 5vw);
  max-width: 5rem;
  max-height: 5rem;
  position: absolute;
  bottom: min(38px, 3%);
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;

  .icon-scroll {
    max-width: 5rem;
    max-height: 5rem;
    bottom: 0;
    background-color: #fff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &,
    svg {
      width: var(--dimensions-intro-mouse, 5vw);
      height: var(--dimensions-intro-mouse, 5vw);
    }

    svg {
      rotate: 180deg;

      display: block;
      max-width: 50%;
    }
  }
}

.bathroom {
  @extend .parallax-bg;

  background-image: url(../images/../new-images/bath_with_a_view_default.jpg);
  height: 99vw;
  max-height: 1920px;

  @media (min-width: 432px) {
    background-image: url(../images/../new-images/bath_with_a_view_432.jpg);
  }

  @media (min-width: 768px) {
    background-image: url(../images/../new-images/bath_with_a_view_768.jpg);
  }

  @media (min-width: 1024px) {
    background-image: url(../images/../new-images/bath_with_a_view_1024.jpg);
  }

  @media (min-width: 1440px) {
    background-image: url(../images/../new-images/bath_with_a_view_1440.jpg);
  }
}

.contact-us {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media only screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.about {
  background: #fff;
  padding: 115px 15px 60px;

  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: max(-2vw, -11px);
    left: 0;
    width: 26vw;
    height: 3px;

    background-color: #fff;

    @media only screen and (max-width: 432px) {
      content: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding-top: 15px;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 576px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__left {
    h2 {
      text-transform: uppercase;
      font-size: 44px;
      font-weight: 600;
      @media only screen and (max-width: 576px) {
        font-size: 28px;
      }
    }

    display: flex;
    color: #000;
    flex-direction: column;
    flex: 0 1 50%;

    @media only screen and (max-width: 576px) {
      flex: 1 1 100%;
    }

    .name {
      text-transform: uppercase;
      padding-bottom: 5px;
      font-size: 20px;
      font-weight: 600;
      position: relative;
      @media only screen and (max-width: 576px) {
        font-size: 18px;
      }

      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 291px;
        height: 2px;
        background: #000000;
      }
    }
  }

  &__right {
    display: flex;
    color: #000;
    flex-direction: column;
    flex: 0 1 50%;

    @media only screen and (max-width: 576px) {
      flex: 1 1 100%;
    }
  }
}

.contacts {
  padding-top: 18px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  color: #000;
}

.advantages {
  padding-top: 60px;
  padding-bottom: 80px;

  background-image: linear-gradient(
    0deg,
    rgba(66, 76, 79, 0.7) 0%,
    rgba(101, 122, 129, 0.9) 50%,
    rgba(101, 122, 129, 0.9) 50%,
    rgba(101, 122, 129, 0) 100%
  );
  background-color: rgba(134, 154, 160, 0.7);
  background-size: 1920px 100%;
  background-position: 50% 0;

  margin-top: -680px;

  @media only screen and (max-width: 1440px) {
    padding: 48px 0;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: -20%;

    background-image: linear-gradient(
      0deg,
      rgba(134, 154, 160, 1) 0%,
      rgba(134, 154, 160, 1) 80%,
      rgba(134, 154, 160, 1) 80%,
      transparent 100%
    );
    background-color: rgba(134, 154, 160, 0.5);
    padding: 30px 15px;
  }

  @media only screen and (max-width: 576px) {
    padding-bottom: 15px;
    padding-top: 30px;
    margin-top: -72px;

    background-image: linear-gradient(
      0deg,
      rgba(134, 154, 160, 1) 0%,
      rgba(134, 154, 160, 1) calc(100% - 72px),
      rgba(134, 154, 160, 1) calc(100% - 72px),
      transparent 100%
    );
  }

  h2 {
    text-align: center;
    margin-bottom: 70px;
    text-transform: uppercase;
    font-weight: 400;

    @media only screen and (max-width: 1440px) {
      margin-bottom: 48px;
    }

    @media only screen and (max-width: 1200px) {
      margin-bottom: 30px;
    }

    @media only screen and (max-width: 576px) {
      font-size: 28px;
    }
  }
}

.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-item {
  display: flex;
  align-items: center;
}

.box {
  display: flex;
  text-align: center;
  flex-direction: column;
  color: #000;
  margin-bottom: 20px;
  background: #fff;
  flex: 0 1 calc(33% - 10px);

  height: 190px;

  @media only screen and (max-width: 1200px) {
    flex: 0 1 calc(50% - 10px);
  }

  @media only screen and (max-width: 576px) {
    flex: 1 1 100%;

    height: auto;
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 32px 0 16px;

    @media only screen and (max-width: 576px) {
      margin: 24px 0 12px;
    }
  }

  p {
    margin: 0 auto;
    max-width: 267px;

    padding-left: 16px;
    padding-right: 16px;

    padding-bottom: 36px;

    @media only screen and (max-width: 576px) {
      padding-bottom: 24px;
    }
  }
}

.visit-us {
  padding-top: 50px;
  padding-bottom: 60px;
  a {
    text-decoration: underline;
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }

  background: url(../images/uzovovky.svg) no-repeat top 7% left 4%,
    url(../images/uvozovky_dolni.svg) no-repeat left 45% bottom 14%;
}

/*# sourceMappingURL=/main.3a106136.css.map */
