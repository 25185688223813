h1, h2, h3 {
  margin-top: 0;


  //text-shadow: 3px 3px 2px red;
}

h1 {
  font-size: 56px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 64px;

  span {
    font-size: 28px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 36px;
    line-height: 1.4;

    margin-bottom: 48px;

    span {
      font-size: 24px;

      display: block;
    }
  }
}

h2 {
  font-size: 36px;
  line-height: 1.4;
}

h3 {
  font-size: 32px;
  line-height: 1.4;

  margin-top: 80px;

  @media only screen and (max-width: 576px) {
    font-size: 24px;

    margin-top: 48px;
  }

}

h4 {
  font-size: 24px;
  line-height: 1.2;

  margin-bottom: 0;
  margin-top: 0;

  @media only screen and (max-width: 576px) {
    font-size: 22px;
  }
}