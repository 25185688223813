html {
  font-family: 'Nunito Sans', sans-serif;
  color: #fff;
}

body {
  margin: 0;
}

section, footer {
  padding-left: 15px;
  padding-right: 15px;
}

.section-container {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;


  @media only screen and (max-width: 1440px) {
    max-width: 1000px;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 800px;
    flex-wrap: wrap;
  }

  h3 {
    margin-top: 0;
  }

  &--row {
    flex-direction: row;
  }

  &--center {
    align-items: center;
  }

  &--wide {
    max-width: 1920px;

    @media only screen and (max-width: 1440px) {
      max-width: 1200px;
    }
  }
}


.parallax-bg {
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}