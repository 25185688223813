
footer {
    margin-top: auto;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: linear-gradient(
      90deg,
      #000000 0%,
      rgba(0, 0, 0, 0) 50%,
      #000000 100%
    );
    background-size: 1920px 100%;
    background-position: 50% 0;
    position: relative;

    .line {
        width: 100%;
        max-width: 1600px;
        height: 1px;

        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 50%,
          rgba(255, 255, 255, 0) 100%
        );

        opacity: 0.3;
        @media only screen and (max-width: 1200px) {
          margin-bottom: 10px;
        }
    }
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 17px;
    }
    a {
      color: #fff;
    }
  }

  .footer-links {
    display: flex;
    padding-bottom: 46px;

    @media only screen and (max-width: 1200px) {
      padding-bottom: 36px;
    }

    @media only screen and (max-width: 576px) {
      flex-wrap: wrap;
      padding-bottom: 0;
    }
  }

  .footer-links__left {
    a {
      font-weight: 300;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    display: flex;
    flex-direction: column;
    flex: 0 1 33%;

    @media only screen and (max-width: 576px) {
      flex: 0 1 100%;
    }
  }

  .footer-links__center {
    display: flex;
    flex-direction: row;
    flex: 0 1 34%;
    @media only screen and (max-width: 576px) {
      flex: 0 1 100%;
      padding-top: 15px;
    }

    .footer-logos {
      display: flex;
      justify-content: center;
      margin-left: 15%;

      &__item {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        img {
          width: 100%;
          max-width: 40%;
          @media only screen and (max-width: 1200px) {
            max-width: 60%;
          }
        }

        &--amadeus {
          @media only screen and (max-width: 1200px) {
            margin-right: 20px;
          }

          img {
            max-width: 70%;
            @media only screen and (max-width: 1200px) {
              max-width: 90%;
            }
          }
        }
      }
    }
  }

  .footer-links__right {
    display: flex;
    text-align: right;
    flex-direction: column;
    flex: 0 1 33%;

    @media only screen and (max-width: 576px) {
      padding: 30px 0 15px;
      flex: 0 1 100%;
      text-align: left;
    }
    img {
      width: 100%;
      max-width: 20%;
    }

    a {
      font-weight: 300;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-bottom {
    padding-bottom: 39px;
    padding-top: 26px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    font-weight: 300;

    @media only screen and (max-width: 1200px) {
      padding: 0;
    }

    @media only screen and (max-width: 576px) {
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
    }

    &__left {
      display: flex;
      align-items: center;

      a {
        padding-left: 5px;
      }

      p {
        margin: 0;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 1 50%;

      @media only screen and (max-width: 576px) {
        justify-content: flex-start;
      }

      p {
        margin: 0;
      }
    }
  }